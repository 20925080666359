.main {
  border: 1px solid transparent;
}

.canvas {
  /* border: 2px solid blue; */
  max-height: 90vh;
}

.hero {
  font-family: 'Fredoka', sans-serif;
  font-size: min(54px, 7vw);
  font-weight: 400;
  letter-spacing: 1.1px;
}

.header {
  /* outline: 1px solid blue; */
  height: 38px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.header__logo {
  font-family: 'Fredoka', sans-serif;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  font-size: 24px;
}

.header__logo:hover {
  cursor: pointer;
  opacity: 0.8;
}

.header__menu {
  /* border: 1px solid green; */
  padding: 0;
  list-style: none;
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: space-between;
}

.menu__item {
  padding: 0;
  margin: 0;
  font-family: Fredoka, sans-serif;
  font-size: 20px;
  /* border: 1px solid black; */
}

.menu__item:hover {
  font-weight: 500 !important;
  cursor: pointer;
}
