.cloud__container {
  /* border: 1px solid red; */
  display: flex;
  height: 700px;
}

.cloud__container a {
  color: inherit;
}

.cloud__text {
  /* border: 1px solid blue; */
  width: 50%;
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Fredoka';
  font-size: 20px;
}

.cloud__obj {
  width: 50%;
  /* outline: 1px solid red; */
}

@media screen and (max-width: 800px) {
  .cloud__container {
    flex-direction: column;
  }

  .cloud__text {
    width: 100%;
    padding-top: 60px;
  }

  .cloud__obj {
    width: 100%;
    height: 70vh;
  }
}
