.mobile__container {
  font-family: Fredoka, sans-serif;
  color: black;
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.mobile__hero {
  font-size: 34px;
  font-weight: 400;
  letter-spacing: 1.1px;
  text-align: center;
}

.mobile__desc {
  text-align: center;
}

.mobile__footer {
  text-align: center;
  width: 80%;
  font-size: 14px;
}

.mobile__links {
  margin: 0 auto;
  width: 70%;
  max-width: 400px;
}

.links__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.links__item {
  margin-bottom: 22px;
}

.links__item a {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
}

.links__item:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: 500px) {
  .mobile__hero {
    font-size: 44px;
  }

  .mobile__desc {
    font-size: 22px;
  }

  .links__item a {
    font-size: 20px;
  }

  .mobile__container footer {
    font-size: 18px;
  }
}
