.links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.links a {
  color: inherit;
  font-size: 12px;
}
