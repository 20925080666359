.contact {
  /* border: 2px solid red; */
  height: 90vh;
  display: flex;
}

.contact__globe {
  height: 100%;
  width: 50%;
  margin-top: 50px;
}

.contact__form {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Fredoka';
  flex-direction: column;
  /* outline: 1px solid blue; */
}

.contact__linkedin {
  display: flex;
  /* border: 1px solid brown; */
  align-items: center;
  justify-content: center;
  font-size: 22px;
  margin-bottom: 18px;
}

.contact__divider {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}

.linkedin {
  margin-left: 5px;
  font-size: 42px;
  color: #0072b1;
}

.linkedin:hover {
  opacity: 0.85;
  cursor: pointer;
}

.kwes-form {
  /* border: 2px solid brown; */
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form__field {
  /* border: 2px solid black; */
  width: 100%;
  margin-bottom: 18px;
}

.form__input {
  outline: none;
  border: 0.1px solid #808080;
  width: 100%;
  height: 42px;
  font-size: 20px;
  font-family: 'Fredoka';
  word-break: break-word;
  border-radius: 5px;
}

.form__field textarea {
  resize: vertical;
  min-height: 150px;
  max-height: 400px;
}

.form__captcha {
  margin: 0 auto;
  padding-top: 4px;
  text-align: center;
  width: 80%;
  font-size: 12px;
}

.form__captcha a {
  color: inherit;
}

.kw-alert-warning {
  display: none !important;
}

.kw-alert-error {
  font-family: 'Fredoka';
  display: none !important; /*Removing additional error message from KwesForms*/
}

.kw-field-error-message {
  font-family: 'Fredoka';
}
.form__label {
}

.form__submitbtn {
  background: black;
  text-align: center;
  color: white;
  border: none;
  width: 180px;
  height: 42px;
  padding-bottom: 4px;
  font-family: 'Fredoka';
  font-size: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.form__submitbtn:hover {
  color: #e0e0e0;
}

@media screen and (max-width: 800px) {
  .contact {
    flex-direction: column;
  }

  .contact__form {
    width: 100%;
    padding-bottom: 22px;
  }

  .contact__globe {
    width: 100%;
    height: 100vh !important;
  }
}
